<template>
  <div class="pageContol enterpriseInternalTrainingOrder">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">企业内训</a>
          <i>></i>
          <a href="javascript:;" @click="doCancel" >资讯管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">{{$route.query.stu == 'add'? '新增':'编辑'}}资讯</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
           <div class="form-box" style="width:70%">
              <el-form ref="ruleForm" label-width="7rem" class="form" :model="ruleForm" :rules="rules">
                <el-form-item label="资讯标题：" prop="informationName">
                  <el-input size="small" v-model="ruleForm.informationName" maxlength="60" placeholder="请输入资讯标题" show-word-limit></el-input>
                </el-form-item>
               <el-form-item label="资讯详情：" prop="context" class="form-item">
                <div
                  ref="editor"
                  class="editor"
                  style="width: 100%; max-width: 1200px"
                />
              </el-form-item>
               
               
              </el-form>
            </div>
            <div class="btn-box flexcc" style="width:70%;padding-bottom: 10px;">
              <el-button @click="doCancel" class="bgc-bv">取 消</el-button>
              <el-button class="bgc-bv" @click="formSave('ruleForm')">确 定</el-button>
            </div>
          </div>
        </div>
      
      </div>
    </div>
 
  </div>
</template>
<script>
import E from "wangeditor"; //wangeditor编辑器
import List from "@/mixins/List"; //引入列表 - 混入
import { resetKeepAlive } from "@/utils/common"; //缓存页面
import { mapGetters } from "vuex";
let editor = {};
export default {
  name: "enterpriseInternalTrainingOrder",
  components: {
  
  },
  mixins: [List],
  data() {
    return {
      ruleForm:{
        informationName:'',
        context:'',
      },
      rules:{
        informationName: [{required:true, message:'请输入标题', trigger:'blur'},],
        context: [{required:true, message:'请输入资讯内容', trigger:'blur'},]
      }
     
    };
  },
  created() {
    if(this.$route.query.informationId) {
        this.getDetail(this.$route.query.informationId)
    }
  },
  mounted() {
    this.editorInit();
  },
  computed: {},
  methods: {
    // 富文本资讯内容
    editorInit() {
      editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
      ];
     editor.config.zIndex = 100;
       editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
      // editor.config.pasteFilterStyle = "text";
      editor.config.pasteIgnoreImg = true;
      editor.config.pasteFilterStyle = false;
      editor.config.customUploadImg = (files, insert) => {
        let extension = files[0].name.substring(
          files[0].name.lastIndexOf(".") + 1,
          files[0].name.length
        );
        let formData = new FormData();
        formData.append("file ", files[0]);
        formData.append("fileType ", extension);
        formData.append("folder ", "LECTURER");
        formData.append("isPublic ", true);
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            insert(result.data.fileURL);
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      };

      editor.config.onchange = (html) => {
        this.ruleForm.context = html;
      };
      editor.config.pasteTextHandle = function (content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editor.create();
    },
    //保存
formSave(formName = "ruleForm") {
      const el = this.$refs[formName];
      el.validate((valid) => {
        if (valid) {
          this.doAjax();
        } else {
          this.$message({
            type: "warning",
            message: "请输入必填项",
          });
        }
      });
    },
    doAjax() {
      let params = {
        informationName: this.ruleForm.informationName,
        informationContent: this.ruleForm.context,
      };
      if(this.ruleForm.informationId) {
        params.informationId = this.ruleForm.informationId;
      }
      this.$post("/biz/information/insert", params)
        .then(() => {
          setTimeout(() => {
            this.$message({
              type: "success",
              message: "保存成功",
            });
            this.doCancel();
          }, 300);
        })
        .catch((err) => {
          return;
        });
    },
    //取消
    doCancel() {
        this.$router.push({
        path: "/web/CustomPrograms/InformationManagement",
        query: {
          refresh: true,
        },
      });
    },
    //编辑获取详情
    getDetail(informationId) {
        this.$post('/biz/information/info',{informationId}).then(res => {
            console.log(res)
            this.ruleForm = {
                ...res.data
            },
            editor.txt.html(res.data.informationContent);
        })
    }
  },
};
</script>
<style lang="less" scoped>
</style>
