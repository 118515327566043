import { render, staticRenderFns } from "./InformationManagementAddEdit.vue?vue&type=template&id=beca47aa&scoped=true"
import script from "./InformationManagementAddEdit.vue?vue&type=script&lang=js"
export * from "./InformationManagementAddEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "beca47aa",
  null
  
)

export default component.exports